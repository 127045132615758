#input-field {
  color: black;
}

#input-field-music {
  color: black;
  width: 300px;
}

.meta-data__container{
padding-top: 20px;
padding-bottom: 40px;
padding: 20px 20px 40px 20px !important;
border-radius: 10px;
}
