.skeleton__wrapper {
  max-height: 200px;
  max-width: 300px;
  height: 300px;
  background-color: rgb(247, 246, 246);

  .skeleton__img {
    width: auto !important;
    height: 40px;
  }
}

.skeleton__content-wrapper {
  padding: 16px 16px 24px 16px;
}

.skeleton__content {
  max-width: 300px;
  &:nth-of-type(1) {
    margin: 10px 0px 15px 0px;
  }
}
