.navbar-routes{
    flex-grow: 1;
    justify-content: right;
    margin-right: 40px;
}

.navbar{
    //background-color: rgb(9 0 40) !important;
    background-color: var(color--primary);
    justify-content: space-between;
}

.navbar-image {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}