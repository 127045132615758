.container {
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 10px;
  .buttonIcon{
    transition: .3s ease-in;
    font-size: 16px;
  }
  border-bottom: 1px solid #BBC4C2;
}

.filterrow_button--selected{
  background: #189AB4 !important;
  color: #fff !important;
}
