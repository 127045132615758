.MuiPaper-root.card_container {
  display: flex;
  flex-direction: column;
  min-height: 180px;
  background-color: rgb(247, 246, 246);
  border-radius: 4px;
  margin: 0px;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    transition: all 0.2s;
    opacity: 0.9;
    -webkit-box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.2);
  }
  .card-_ontent {
    padding: 10px;
    z-index: 1000;
  }
}
