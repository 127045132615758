@font-face {
  font-family: "Raleway-bold";
  src: url("./fonts/Raleway/static/Raleway-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway-regular";
  src: url("./fonts/Raleway/static/Raleway-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway";
  src: url("./fonts/Raleway/Raleway-VariableFont_wght.ttf") format("truetype");
}

body,
html {
  margin: 0;
  font-family: 'Raleway', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

html {
  height: 100%;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
}
