.description__drawer {
  width: 25vw;
  max-width: 50vw;
  padding: 20px;
  padding: 15px 20px;
}

.description__add {
  align-self: center;
}

.description__content {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.description__measure div {
  margin-right: 8px;
}

.description__content-block {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.02);
}

.text--size-sm {
  font-size: 12px;
  font-weight: 300;
  margin: 0;
}
.text--size-md {
  font-size: 16px;
  font-weight: 600;
}
.text--size-lg {
  font-size: 24px;
  font-weight: 300;
}
