.main-wrapper.MuiGrid-root {
  box-sizing: content-box;
  justify-content: center;
  padding: 20px 0px;
  width: auto;
  gap: 20px;
  flex-wrap: nowrap;
}
.default-content-wrapper {
  background-color: black;
  color: #fff;
  padding: 25px;
  justify-content: center;
  border-radius: 8px;
  min-height: 100%;
}

.dance-grid.MuiGrid-root {
  background-color: #fff;
  margin-bottom: 10px;
  min-width: 520px;
  max-width: 100%;
  min-height: 601px;
  align-items: center;
  justify-content: center;
  max-width: 1082px;
  max-height: 800px;
  border-radius: 4px;
}

.grid {
  background-image: linear-gradient(black 1px, transparent 1px),
    linear-gradient(90deg, black 1px, transparent 1px),
    linear-gradient(rgba(0, 0, 0, 0.3) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.3) 1px, transparent 1px);
  background-size: 120px 120px, 120px 120px, 20px 20px, 20px 20px;
}

.hide-grid {
  background-image: none;
  border: 1px solid #000;
}

.dance-grid-constraints {
  min-width: 750px;
  width: 100%;
  min-height: 600px;
}

.main-content {
  max-width: 1082px;
  width: 100%;
}

.carousel-container {
  display: flex;
  align-items: center;
  overflow-x: hidden;
  min-width: 12rem;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.side-menu.MuiGrid-root {
  min-width: 240px;
  display: flex;
  width: 60%;
  color: black;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 12px;
  border-radius: 10px;
  align-items: center;
}

.secondary-content {
  background-color: #f8f8f8;
  border-radius: 6px;
  min-width: 50%;
}

.action-buttons-wrapper {
  gap: 20px;
}
