.separator {
  width: 100%;
}

.separator--size-sm {
  height: 8px;
}
.separator--size-md {
  height: 12px;
}
.separator--size-lg {
  height: 16px;
}
