.carousel:after {
  box-shadow: 0px 0px 60px 40px black inset;
}
.carousel {
  cursor: grab;
}
.dance-block-container {
  display: flex;
  justify-content: flex-start;
  max-width: 50rem;
  overflow: hidden;
  min-width: 100%;
  position: relative;
  height: max-content;
  padding: 2px;
  .close-icon {
    top: 2px;
    left: 40px;
    position: relative;
    width: 16px;
  }

  &:hover {
    .close-icon {
      opacity: 0.5;
    }
  }

  .inner-carousel {
    display: flex;
    gap: 12px;
    align-items: center;
  }
}

.close-icon {
  opacity: 0;
  transition: all ease 0.2s;

  &:hover {
    opacity: 1;
    scale: 1.03;
  }
}

.dance-block {
  width: 120px;
  height: 64px;
  border: 1px solid black;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  z-index: 1;
  background-color: rgba($color: #000000, $alpha: 0.02);
  &:hover {
    opacity: 0.9;
    scale: 1.03;
    transition: all ease 0.2s;
  }
  p {
    margin-bottom: 16px;
  }
}
.dance-block.add {
  cursor: pointer;
}

.selected {
  background-color: var(--color-secondary);
}

.dance-block:last-of-type {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
  font-size: 20px;
  font-weight: 600;
}

.slider {
  position: absolute;
  top: 10px;
  z-index: 0;
  width: 121px;
  height: 82px;
  border-radius: 6px;
  background-color: #007bff;
  transition: left 0.3s ease;
  :hover {
    scale: 1.03;
    transition: all 0.3s;
  }
}
